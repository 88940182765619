<script setup lang="ts">
const { LocationSearch }: any = useAuth()
const emit = defineEmits()
const { $JsonRaw }: any = useNuxtApp()
const props: any = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})

const LocationQuery: any = ref(null)
const LocationData: any = ref([])
const LoadLocation = async () => {
  const l = await LocationSearch(LocationQuery.value || '')
  if (l && l.data && l.data.length) {
    LocationData.value = $JsonRaw(l.data || [])
  }
}
let LocationSearchTimeout: any = null
watch(() => LocationQuery.value, (v: string) => {
  LocationData.value = []
  if (v) {
    if (v.length <= 2) {
      emit('update:modelValue', null)
    }
    if (LocationSearchTimeout) {
      clearTimeout(LocationSearchTimeout)
    }
    LocationSearchTimeout = setTimeout(() => {
      LoadLocation()
    }, 300)
  } else {
    emit('update:modelValue', null)
  }
})

watch(() => props.modelValue, (v: any) => {
  if (v && v.name_full) {
    LocationQuery.value = v.name_full
  }
}, { immediate: true, deep: true })

</script>
<template>
  <div class="flex w-full items-center pt-1 relative">
    <textarea v-model="LocationQuery" autofocus spellcheck="false" type="text" class="uppercase block bg-transparent w-full outline-none ml-[2px]" :rows="props.modelValue?.name_full ? '2' : '1'" placeholder="Search location" @click.prevent="(e: any) => e.target.select()"></textarea>
    <div
      v-if="!(LocationQuery === props.modelValue?.name_full) && LocationData.length"
      class="absolute left-0 top-[34px] w-full z-10 bg-gray-100 dark:bg-gray-900 px-4 py-2 max-h-[200px] overflow-y-auto rounded border rounded-b-lg shadow-lg cursor-pointer scrollbar-mini"
    >
      <div v-for="(ll, iLl) in LocationData" :key="'location-kelurahan' + iLl" class="w-full py-1 text-sm uppercase" @click="emit('update:modelValue', $JsonRaw(ll))">
        {{ ll.name_full }}
      </div>
    </div>
  </div>
</template>